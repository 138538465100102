<template>
   <v-row justify="center">
    <v-dialog v-model="shower"  scrollable  max-width="500px" persistent>
      <!-- Addrelation -->
      <v-card v-if="statusopen === '1'" >
        <v-card-title >{{$t('relationMyfile.relationname') }}{{relation_name}}
          <!-- <v-icon :color="color.theme" dense dark size="35">mdi-tag-multiple</v-icon> -->
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                    <v-card>
              <v-container fluid >
                  <v-row align="center">
                  <v-col cols="12">
                      <v-list>
                        <v-subheader>{{$t('relationMyfile.chooserelation') }}</v-subheader>
                       <v-list nav >
            <v-list-item class="text-left">
              <v-list-item-avatar class="text-center">
                <v-avatar :color="color.theme" size="35">
                  <v-icon dark size="20">folder</v-icon>                
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                   <span  :style="headerPage" >{{navigate_name}}</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-group>
                <!-- <v-list-item-action> -->
                <v-btn
                  icon
                  fab
                  small
                  text
                   @click="backtopreventfolder()"
                  :color="color.theme"
                >
                  <v-icon>keyboard_backspace</v-icon>
                </v-btn>
                <!-- </v-list-item-action> -->
              </v-list-item-group>
            </v-list-item>
          </v-list>
                            <v-divider></v-divider>
                            <div v-if="loaddataprogress === true" class="text-center">
                        <v-progress-circular
                            indeterminate
                            size="64"
                            :color="color.theme"
                          >
                          <span :style="colorProgress">&nbsp; &nbsp; loading</span>
                          </v-progress-circular>
                      </div>
                      <div v-else>
                        <v-list v-if="datarelation.length !== 0">
                        <v-list-item v-for="(item,i) in datarelation" :key="i" @dblclick="gotodirectory(item.file_id,item.file_type,item)">
                          <v-list-item-action><v-icon large :color="item.file_icon[1]">{{item.file_icon[0]}}</v-icon></v-list-item-action>
                          <v-list-item-content class="pl-2 pointer">                           
                             <v-list-item-title v-text="item.file_name"></v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action><v-checkbox v-model="selected" :value="item"></v-checkbox></v-list-item-action>                   
                        </v-list-item>
                        </v-list>
                        <v-list v-else class="elevation-1">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-center"
                              v-text="$t('tablefile.empty')"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                        </div>
                      </v-list>
                  </v-col>
                  </v-row>
              </v-container>
                    </v-card>
            </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="$emit('closedialog'),selected=[],datarelation=[],navigatefolder = []">{{$t('uploadfolder.buttonclose') }}</v-btn>
           <v-btn :color="color.theme" dark @click="loadapi(),navigatefolder = []">{{$t('changeName.submit')}}</v-btn> 
        </v-card-actions>
      </v-card>

<!-- Createrelation -->
      <v-card v-if="statusopen === '2'">
          <v-card-title>{{$t('relationMyfile.createrelation') }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
              <v-layout row wrap align-center justify-center>
                  <v-flex lg11 xs11 ma-5>
                    <v-card>
                        <v-container fluid>
                            <v-row align="center">
                            <v-col cols="12">
                                <v-layout row wrap justify-center>
                                    <v-flex lg10 xs11 class="text-center">
                                        <v-text-field
                                        :label="$t('relationMyfile.namerelation')"
                                        v-model="namerelation"
                                        persistent-hint
                                        outlined
                                        dense
                                        >
                                        </v-text-field>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                    <v-flex lg10 xs11 class="text-center">
                                        <v-text-field
                                        label="Filename"
                                       
                                        v-model="filedata.file_name"
                                        persistent-hint
                                        outlined
                                        dense
                                        readonly
                                        >
                                        </v-text-field>
                                    </v-flex>
                                </v-layout>
                                <div v-if="loaddataprogress === true" class="text-center">
                                  <v-progress-circular
                                      indeterminate
                                      size="64"
                                      :color="color.theme"
                                    >
                                    <span :style="colorProgress">&nbsp; &nbsp; loading</span>
                                    </v-progress-circular>
                                </div>
                                <v-list>
                                  <v-subheader>{{$t('relationMyfile.chooserelation') }}</v-subheader>
                                  <v-list nav >
            <v-list-item class="text-left">
              <v-list-item-avatar class="text-center">
                <v-avatar :color="color.theme" size="35">
                  <v-icon dark size="20">folder</v-icon>                
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                   <span  :style="headerPage" >{{navigate_name}}</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-group>
                <!-- <v-list-item-action> -->
                <v-btn
                  icon
                  fab
                  small
                  text
                   @click="backtopreventfolder()"
                  :color="color.theme"
                >
                  <v-icon>keyboard_backspace</v-icon>
                </v-btn>
                <!-- </v-list-item-action> -->
              </v-list-item-group>
            </v-list-item>
          </v-list>
                                      <v-divider></v-divider>
                                  <v-list-item v-for="(item,i) in datarelation" :key="i" @dblclick="gotodirectory(item.file_id,item.file_type,item)">
                                    <v-list-item-content  class="pl-2 pointer">
                                      <v-checkbox v-model="selected2" :label="item.file_name" :value="item"></v-checkbox>
                                    </v-list-item-content>
                                    <v-list-item-icon><v-icon large :color="item.file_icon[1]">{{item.file_icon[0]}}</v-icon></v-list-item-icon>
                                  </v-list-item>
                                </v-list>
                            </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                  </v-flex>
              </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
              <v-spacer></v-spacer>
             <v-btn
              color="red"
              outlined
              @click="$emit('closedialog'),namerelation='',selected2=[],Filenamerelation='',datarelation=[],navigatefolder = []"
            >{{$t('uploadfolder.buttonclose') }}</v-btn>
            <v-btn
            :color="color.theme"
            :dark="color.darkTheme"
            @click="createRelation(),namerelation='',selected2=[],Filenamerelation='',datarelation=[],navigatefolder = []"
            >{{$t('changeName.submit')}}</v-btn>

          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row> 
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});
  export default {
    props:["show","filedata","relation_name","statusopen"],
    data () {
      return {
        navigate_name:'',
        navigatefolder:[],
        parent_folder:'',
        id:'',
        name:'',
        Filenamerelation:'',
        namerelation:'',
        checkstatus:[],
        selected:[],
        selected2:[],
        newfile:[],
        datarelation:[],
        loaddataprogress:true,
        
      }
    },
    computed:{
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId"
    ]),
      shower: {
      get() {
        if (this.show === true) {
           
        }
        return this.show;
      },
        },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    headerPage(){
      // console.log(this.color.theme);
      
      return 'color:'+this.color.theme+';'+'font-size: 18px; font-weight: 600;'
    }
    },
    watch:{
      show:function(val){
        if( val === true){
          this.checkloaddata()
          console.log(this.filedata);
          this.navigate_name = this.account_active["business_info"]["first_name_th"];
        }else{
          // this.$emit('openDrag');
          this.namerelation=''
          this.selected2=[]
          this.Filenamerelation=''
          this.datarelation=[]
          this.navigatefolder = []
          this.navigate_name = ''
        }
      }
    },   
    methods:{
      backtopreventfolder(){    
      console.log(this.selected);
      this.selected = []
        this.selected2 = []
      let folder_id = ''
      console.log(this.navigatefolder);
      //for(let i = 0 ; i<this.navigatefolder.length ; i++){
        this.navigatefolder.pop();
        if(this.navigatefolder.length === 0){
          this.loaddataprogress = true
          this.checkloaddata();
          this.navigate_name = this.account_active["business_info"]["first_name_th"];
        }
        folder_id = this.navigatefolder[this.navigatefolder.length-1].file_id;
         this.navigate_name = this.navigatefolder[this.navigatefolder.length-1].file_name;
         console.log(this.navigate_name);
     // }
      console.log(folder_id);
      this.loaddataprogress = true;
      this.checkloaddata(folder_id);
      //this.navigate.pop();
      
      //}
    },
      gotodirectory(folderid, type, file) {
        console.log(this.selected);
        this.selected = []
        this.selected2 = []
      if (type === "folder") {
          this.navigatefolder.push({file_id:folderid,file_name:file.file_name});
          this.navigate_name = file.file_name;
          console.log(this.navigate_name);
         console.log(this.navigatefolder);
        //this.parent_folder = folderid;
        this.loaddataprogress = true
        this.checkloaddata(folderid);
      } else {
       
      }
    },
      addselected(item){
        let result = this.selected.findIndex((a) => a === item)
        console.log(result);
        if(result !== -1){
          this.selected.pop(item);
        }else{
          this.selected.push(item);
        }
        //console.log(this.se);
      },
    createRelation(){
      if(this.namerelation === ''){
        Toast.fire({
                icon: "error",
                title: "Please input relation name"
                });
      }else{
       let dataChild =[];
            for(let i =0 ; i< this.selected2.length;i++){
                let databuffer={}
                databuffer["data_id"] = this.selected2[i]["file_id"];
                databuffer["data_type"] = this.selected2[i]["file_type"]
                dataChild.push(databuffer);
            }
            console.log(dataChild);
      var payload = {
            data_list: dataChild,
            account_id: this.dataAccountId,
            relation_name: this.namerelation,
            data_id_root: this.filedata.file_id,
            data_type_root: this.filedata.file_type,
            }
             this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/insert_relation_file_folder_with_child",
          payload
        )
        .then(response => {
          console.log("Apifolder", response);
          // console.log("count",response.data.folder.length);
          if (response.data.status === "OK") {
              Toast.fire({
                icon: "success",
                title: "Success"
                });
                this.$emit('closedialog')
                this.namerelation='',this.selected2=[],this.Filenamerelation='',this.datarelation=[] 
                this.$emit('checkparameter',{relation_id:response.data.relation_id,relation_name:response.data.relation_name})        
          }else{
            Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
                this.$emit('closedialog')
                this.namerelation='',this.selected2=[],this.Filenamerelation='',this.datarelation=[] 
          }
        }).catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
      }
    },
    loadapi(){
            console.log(this.selected.length);
            let dataChild =[];
            for(let i =0 ; i< this.selected.length;i++){
                let databuffer={}
                databuffer["data_id"] = this.selected[i]["file_id"];
                databuffer["data_type"] = this.selected[i]["file_type"]
                console.log(this.selected);
                dataChild.push(databuffer);
            }
            console.log(dataChild);
            
            var payload = {
                data_child:dataChild,
                data_id_root: this.filedata.file_id,
                account_id: this.dataAccountId,
                relation_id: this.filedata.relation_id
            }
            this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/insert_relation_file_folder_to_root",
          payload
        )
        .then(response => {
          console.log("Apifolder", response);
          // console.log("count",response.data.folder.length);
          if (response.data.status === "OK") {
              Toast.fire({
              icon: "success",
              title: "Success"
              });
              this.$emit('closedialog')
              this.namerelation='',this.selected=[],this.Filenamerelation='',this.datarelation=[]         
          }else{
              Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
              this.$emit('closedialog')
              this.namerelation='',this.selected=[],this.Filenamerelation='',this.datarelation=[] 
          }
          }).catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
  async checkloaddata(folder_id){
      this.selected = []
      this.selected2 = []
     if(folder_id){
        var payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: folder_id,
        status_folder: ""
      };
      console.log(payload);
      }else {
        var payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: this.$route.params.id,
        status_folder: ""
      };
      console.log(payload);
      }
       let auth = await gbfGenerate.generateToken(); 
      // console.log(payload);
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FOLDER +
            "/api/v2/search_folder_byid",
          payload,{headers: { Authorization: auth.code },}
        )
        .then(response => {
          console.log("Apifolder", response);
          this.datarelation=[];
          // console.log("count",response.data.folder.length);
          if (response.data.status === "OK") {
              
              this.loaddataprogress=false;
              for(let i =0 ; i<response.data.folder.length;i++){
                
                  if(response.data.folder[i]["relation_status"] === 2){
                      let datafolder = {}
                        datafolder["file_id"] = response.data.folder[i]["folder_id"];
                        datafolder["file_name"] = response.data.folder[i]["folder_name"];
                        datafolder["file_size"] = "-";
                        datafolder["file_lastdtm"] = response.data.folder[i]["cre_dtm"] || "-";
              datafolder["file_date"] = "";
              datafolder["file_linkshare"] = response.data.folder[i]["link"];
              datafolder["file_status_share"] = response.data.folder[i]["status_share"];
              datafolder["file_status_sharelink"] =response.data.folder[i]["status_share_link"];
              datafolder["file_status"] = response.data.folder[i]["folder_status"];
              datafolder["file_owner_eng"] = response.data.folder[i]["name_eng"];
              datafolder["file_owner_th"] = response.data.folder[i]["name_th"];
              datafolder["file_type"] = "folder";
              datafolder["file_department"] = "";
              datafolder["file_icon"] = ["folder", "#FDD361"];
              datafolder["file_parent_folder_id"] =response.data.folder[i]["parent_folder_id"];
              datafolder["file_permission"] =
                response.data.folder[i]["permission_setting"] === "True"
                  ? response.data.folder[i]["permission_account"]
                  : response.data.your_folder[0]["permission_account"];
              datafolder["file_permission_department_setting"] =
                response.data.folder[i]["permission_setting"] === "True"
                  ? response.data.folder[i]["permission_department_setting"]
                  : response.data.your_folder[0][
                      "permission_department_setting"
                    ];
              datafolder["file_permission_setting"] =
                response.data.folder[i]["permission_setting"] === "True"
                  ? response.data.folder[i]["permission_setting"]
                  : response.data.your_folder[0]["permission_setting"];
                  datafolder["relation_id"]=response.data.folder[i]["relation_id"];
                  datafolder["relation_name"]=response.data.folder[i]["relation_name"];
                  datafolder["relation_status"]=response.data.folder[i]["relation_status"];
                  this.datarelation.push(datafolder);
                  }
              }         
          }else{
             Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
          }
          this.checkloaddatafile(folder_id);
          }).catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
     async checkloaddatafile(folder_id){
      console.log("112211221",this.account_active["directory_id"]);
      this.selected = []
      this.selected2 = []
     if(folder_id){
        var payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: folder_id,
        status_folder: ""
      };
      console.log(payload);
      }else {
        var payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: this.$route.params.id,
        status_folder: ""
      };
      console.log(payload);
      }
        let auth = await gbfGenerate.generateToken();
        this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/v2/search_file",
          payload,{headers: { Authorization: auth.code },}
        )
        .then((response) => {
          // console.log(response);
          if (response.data.status === "OK") {
             //this.parent_folder = ''
            console.log(response.data);
            let rawdata = response.data.data;
            for(let i =0 ; i<response.data.data.length;i++){
              if(response.data.data[i]["relation_status"] === 2){
             let datafolder = {};
              let typefile = rawdata[i]["filename"].split(".");
              datafolder["account_reciever"] = "";
              datafolder["account_sender"] = this.dataAccountId;
              datafolder["file_id"] = rawdata[i]["id"];
              datafolder["file_name"] = rawdata[i]["filename"];
              datafolder["file_size"] = rawdata[i]["size_file"];
              datafolder["file_lastdtm"] =
                response.data.data[i]["last_dtm"] || "-";
              datafolder["file_date"] = "";
              datafolder["file_status"] = rawdata[i]["status_file"];
              datafolder["file_owner_eng"] = rawdata[i]["name_eng"];
              datafolder["file_owner_th"] = rawdata[i]["name_th"];
              datafolder["file_linkshare"] = rawdata[i]["link"];
              datafolder["file_status_share"] = rawdata[i]["status_share"];
              datafolder["file_status_sharelink"] =
                rawdata[i]["status_share_link"];
              datafolder["file_type"] = typefile[typefile.length - 1];
              datafolder["file_department"] = "";
             datafolder["file_icon"] = this.seticon(typefile[typefile.length - 1]);
              
              datafolder["relation_id"]=rawdata[i]["relation_id"];
              datafolder["relation_name"]=rawdata[i]["relation_name"];
              datafolder["relation_status"]=rawdata[i]["relation_status"];
              this.datarelation.push(datafolder);
  
              }

            }
            
            console.log(this.datarelation);
            
          }else{
             Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
          }
          }).catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
      seticon(parameter) {
      // console.log(parameter);
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word-outline", "primary"];
      } else if (parameter === "zip") {
        dataicon = ["mdi-zip-box-outline", "blue-grey"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if(parameter === "avi" ||
    parameter === "mpeg" ||
    parameter === "mov" ||
    parameter === "mp4"||
    parameter === "mkv" ||
    parameter === "wmv" ||
    parameter === "3gp" ||
    parameter === "flv"){
      dataicon = ["mdi-file-video-outline", "purple"];
    }else if(parameter === "shortcut"){
      dataicon=["mdi-file-link-outline","pink"]
    }else {
        dataicon = ["mdi-file-question-outline", "black"];
      }

      return dataicon;
    },
}
}
</script>
<style>
.pointer {
  cursor: pointer;
}
</style>